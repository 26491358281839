<template>
  <router-link :to="{name: 'EventDetails', params: {id: event.id}}">
    <div class="event-card">
      <span>@ {{event.time}} on {{event.date}}</span>
      <h4>{{event.title}}</h4>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: Object,
  },
  data() {
    return {
      
    }
  }
};
</script>

<style scoped>
.event-card {
  padding: 2rem;
  border: 2px solid #333;
  display: inline-block;
  margin: 1rem;
}
</style>
